<template>
  <div class="my-breadcrumb" v-if="breadcrumbList !== undefined && breadcrumbList != null && breadcrumbList.length > 0">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      v-if="breadcrumbList !== undefined && breadcrumbList != null && breadcrumbList.length > 0"
    >
      <el-breadcrumb-item
        v-for="(item, index) in breadcrumbList"
        v-show="
          ($route.path == '/goodsDetail' && item.name == '首页') ||
          item.name != '首页'
        "
        :to="item.path"
        :key="index"
        :replace="true"
        >{{ item.name }}</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ breadcrumbCurr.name }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
const addmallList = ["/addGoods", "/addBiddingGoods"];
const otherList = [
  "/released",
  "/purchase",
  "/purchasehangtag",
  "/markethangtag",
];
export default {
  data() {
    return {
      breadcrumbList: new Array(),
      breadcrumbCurr: {},
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/mall") {
        this.format(to);
        this.setactive();
        return;
      }
      this.breadcrumbList = to.meta.breadcrumbList || [];
      this.breadcrumbCurr = { name: to.meta.title };
      let paths = this.breadcrumbList[this.breadcrumbList.length - 1];
      if (paths) {
        let path = paths.path;
        if (from.fullPath.includes(path)) {
          this.breadcrumbList[this.breadcrumbList.length - 1].path =
            from.fullPath;
        }
      }


      // if (to.path == "/goodsDetail") {
      //   this.goodsDetailformat(to,from);
      //   this.setactive();
      //   return;
      // }
      if (addmallList.includes(to.path) && !otherList.includes(from.path)) {
        this.formats(from);
        this.setactive();
        return;
      }
      if (addmallList.includes(to.path) && otherList.includes(from.path)) {
        this.breadcrumbList = from.meta.breadcrumbList || [];
        this.breadcrumbCurr = { name: to.meta.title };
        this.setactive();
        return;
      }
      this.setactive();
    },
  },
  mounted() {
    // console.log(this.$route, '------------route--------')
    let storage = window.localStorage;
    this.breadcrumbList = JSON.parse(storage.getItem("breadcrumbList"));
    // console.log(this.breadcrumbList, '-------breadcrumbList-----')
    this.breadcrumbCurr = JSON.parse(storage.getItem("breadcrumbCurr"));
  },
  methods: {
    goodsDetailformat(to, from) {
      let fullPath = to.fullPath;
      let selectTradeType = this.getQueryVariable(fullPath, "selectTradeType"),
        selectOrderType = this.getQueryVariable(fullPath, "selectOrderType");
      this.breadcrumbList = [...to.meta.breadcrumbList];
      let name = `${selectTradeType == 1 ? "挂牌" : "竞价"}${
        selectOrderType == 1 ? "销售" : "采购"
      }`;
      this.breadcrumbList.push({
        path:
          "/mall?selectTradeType=" +
          selectTradeType +
          "&selectOrderType=" +
          selectOrderType,
        name,
      });
      this.breadcrumbCurr = { name: name + "详情" };
    },
    setactive() {
      let storage = window.localStorage;
      storage.setItem("breadcrumbList", JSON.stringify(this.breadcrumbList));
      storage.setItem("breadcrumbCurr", JSON.stringify(this.breadcrumbCurr));
    },
    formats(to) {
      let fullPath = to.fullPath;
      let selectTradeType = this.getQueryVariable(fullPath, "selectTradeType"),
        selectOrderType = this.getQueryVariable(fullPath, "selectOrderType");
      this.breadcrumbList = [...to.meta.breadcrumbList];
      let name = `${selectTradeType == 1 ? "挂牌" : "竞价"}${
        selectOrderType == 1 ? "销售" : "采购"
      }`;
      this.breadcrumbList.push({
        path:
          "/mall?selectTradeType=" +
          selectTradeType +
          "&selectOrderType=" +
          selectOrderType,
        name,
      });
      this.breadcrumbCurr = { name: "发布" + name };
    },
    format(to) {
      let fullPath = to.fullPath;
      let selectTradeType = this.getQueryVariable(fullPath, "selectTradeType"),
        selectOrderType = this.getQueryVariable(fullPath, "selectOrderType");
      this.breadcrumbList = [...to.meta.breadcrumbList];
      let name = `${selectTradeType == 1 ? "挂牌" : "竞价"}${
        selectOrderType == 1 ? "销售" : "采购"
      }`;
      this.breadcrumbCurr = { name: name };
    },
    getQueryVariable(path, key) {
      var vars = path.split("?")[1].split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == key) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-breadcrumb {
  width: 1194px;
  margin: 28px auto;
}
.el-breadcrumb {
  margin-top: 10px;
}
</style>